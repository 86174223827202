export const useReviewIds = () => {
	const reviewIdsArray = useState<string[]>("reviewIds", () => []);

	const setReviewIds = (reviewIds: string[]) => {
		reviewIdsArray.value = reviewIds;
	};

	return {
		reviewIds: computed(() => reviewIdsArray.value),
		setReviewIds
	};
};
